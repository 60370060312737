<nav class="navbar" appScroll [ngClass]="showingQr ? 'downgrade-zindex' : ''" *ngIf="showNav">
  <div class="wrapper">
    <a class="logo__link" href="/" target="_self">
      <img src="../../../../assets/logo/{{ logoName }}" class="logo__image" [ngClass]="classLogo + '_logo'" alt="logo"/>
    </a>
    <div class="search-event" *ngIf="isLogin()" #searchBar>
      <div class="searchbar" *ngIf="showSearchBar">
        <i class="fa-light fa-magnifying-glass text-primary icon__glass"></i>
        <input placeholder="Buscar evento" class="searchbar--input" (keyup)="searchEvents(textSearch)"
               [(ngModel)]="textSearch" (click)="openResults()" i18n-placeholder/>
        <div class="input__state">
          <i class="fa-solid fa-spinner-third spinner" *ngIf="searching"></i>
        </div>
      </div>
      <div class="searchbar-results" *ngIf="search && textSearch?.length > 2" #dropdown>
        <ul class="results-list">
          <li class="result-event" tabindex="0" *ngFor="let event of resultSearch" (click)="eventDetail(event)">
            <div class="flex flex-row">
              <img src="{{ mediaUrl + event?.image }}" class="thumbnail__event" alt="">
              <div class="flex flex-col ml-4">
                <span class="text-sm lh-24 font-secondary font-weight-400"
                      innerHTML="{{ event.eventName | highlight : textSearch}}">
                </span>
                <span class="text-xs lh-24 font-weight-400">
                  {{event.dateInit | date:dateFormat | uppercase}} '{{event.dateInit | date:'yy'}},
                  {{event.dateInit | date: 'HH:mm' }} hs
                </span>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="account">
      <button class="btn__my-qr" *ngIf="logged" (click)="openModalMyQr()">
        <i class="fa-light fa-qrcode font-size-12 lh-16 text-primary margin-right-8"></i>
        <span i18n>Mi QR</span>
      </button>
      <button class="btn__event-code" *ngIf="logged && showButtonEventCode" (click)="openModalEventCode()">
        <i class="fa-light fa-calendar-star font-size-12 lh-16 text-primary"></i>
        <span i18n>Ingresar código de evento</span>
      </button>
      <button class="btn__contact" *ngIf="!logged" [ngClass]="!logged && !showSignInBtn ? 'mr-0' : ''"
              (click)="contactSupport()">
        <i class="fa-light fa-user-headset icon__contact"></i>
      </button>
      <button class="btn__language" [ngClass]="!logged ? 'on-navbar' : ''" *ngIf="!logged">
        <div class="globe-icon" (click)="openLanguageMenu()">
          <i class="fa-light fa-globe icon__contact"></i>
        </div>
        <div class="languageMenu" *ngIf="languageMenu">
          <div class="row" *ngFor="let locale of locales" (click)="changeLocale(locale)">
            <img class="icon-img" src="/assets/icon/{{locale.name}}.png" alt=""/>
            <p class="text-sm lh-16 text-gray-1 font-normal my-0 ml-3">{{locale.label}}</p>
          </div>
        </div>
      </button>
      <button class="btn btn__login btn__secondary" *ngIf="!logged && showSignInBtn" (click)="login()"
              i18n="Login button label">
        Ingresar
      </button>
      <button (click)="openMenu()" class="btn__profile" [ngClass]="viewMenu ? 'upgrade-index' : ''" #btnProfile
              *ngIf="logged">
        <div class="profile-pic" *ngIf="userData?.image">
          <img src="{{ mediaUrl + userData?.image }}" class="profile__image" alt="">
        </div>
        <i class="icon fa-light fa-circle-user text-3xl text-gray-4" *ngIf="!userData?.image"></i>
      </button>
    </div>
  </div>
</nav>
