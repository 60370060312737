<footer class="footer" *ngIf="showFooter" [ngClass]="{'hide': hideOnDesktop}">
  <div class="wrapper">
    <div class="brand">
      <a href="{{ url }}" target="_self">
        <img src="../../assets/logo/{{ logoName }}" class="logo" [ngClass]="classLogo + '_logo'" alt="logo"/>
      </a>
      <p class="copyright">
        <ng-container i18n>Copyright©</ng-container>
        {{ date }}
        <ng-container i18n>. Todos los derechos reservados.</ng-container>
      </p>
    </div>
    <div class="social-media">
      <a href="{{environment.brandParams.instagramUrl}}" *ngIf="environment.brandParams.facebookUrl !== null"
         target="_blank">
        <i class="fa-media fa-brands fa-instagram"></i>
      </a>
      <a href="{{environment.brandParams.facebookUrl}}" *ngIf="environment.brandParams.facebookUrl !== null"
         target="_blank">
        <i class="fa-media fa-brands fa-facebook"></i>
      </a>
    </div>
  </div>
</footer>
