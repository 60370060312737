<span class="display-block font-secondary font-size-18 lh-24 font-weight-600 text-gray-1 margin-bottom-8" i18n>
  Registrate en dos simples pasos!
</span>
<form action="" [formGroup]="parentForm">
  <div
    class="field__item  margin-top-32"
    [ngClass]="validateInputClass(parentForm, 'name', stepHasBeenSubmitted)"
  >
    <div class="p-float-label">
      <input type="text"
             formControlName="name"
             id="name"
             class="p-inputtext"
             pattern="^[\u00F1\u00D1A-Za-zÀ-ÿ _]*[\u00F1\u00D1A-Za-zÀ-ÿ][\u00F1\u00D1A-Za-zÀ-ÿ _]*$"
      >
      <label for="name" class="title-float" i18n>Nombre</label>
      <app-error-message
        [page]="'register'"
        [ctrl]="parentForm.get('name') | formControl"
        [formHasBeenSubmitted]="stepHasBeenSubmitted"
      ></app-error-message>
    </div>
  </div>

  <div
    class="field__item  margin-top-32"
    [ngClass]="validateInputClass(parentForm, 'lastName', stepHasBeenSubmitted)"
  >
    <div class="p-float-label">
      <input type="text"
             formControlName="lastName"
             id="lastName"
             class="p-inputtext"
             pattern="^[\u00F1\u00D1A-Za-zÀ-ÿ _]*[\u00F1\u00D1A-Za-zÀ-ÿ][\u00F1\u00D1A-Za-zÀ-ÿ _]*$"
      >
      <label for="lastName" class="title-float" i18n>Apellido</label>
      <app-error-message
        [page]="'register'"
        [ctrl]="parentForm.get('lastName') | formControl"
        [formHasBeenSubmitted]="stepHasBeenSubmitted"
      ></app-error-message>
    </div>
  </div>

  <div
    class="field__item  margin-top-32"
    [ngClass]="validateInputClass(parentForm, 'email', stepHasBeenSubmitted)"
  >
    <div class="p-float-label">
      <input type="text"
             formControlName="email"
             id="email"
             class="p-inputtext"
             (focusout)="clearEmail()"
             autocapitalize="none"
             [readonly]="readOnly"
      >
      <label for="email" class="title-float" i18n>Email</label>
      <app-error-message
        [page]="'register'"
        [ctrl]="parentForm.get('email') | formControl"
        [formHasBeenSubmitted]="stepHasBeenSubmitted"
      ></app-error-message>
    </div>
  </div>

  <bid-input-password
    class="margin-bottom-24 display-block"
    [page]="'password'"
    [parentForm]="parentForm"
    [controlName]="'password'"
    [formHasBeenSubmitted]="stepHasBeenSubmitted"
    [label]="labelInputPassword"
    [passwordMatch]="passwordMatch"
    [showLabelRequirement]="true"
  ></bid-input-password>

  <bid-input-password
    class="margin-bottom-24 display-block"
    [page]="'password'"
    [parentForm]="parentForm"
    [controlName]="'confirmPassword'"
    [formHasBeenSubmitted]="stepHasBeenSubmitted"
    [label]="labelInputRepeatPassword"
    [passwordMatch]="passwordMatch"
  ></bid-input-password>

</form>
