<div class="modal">
  <div class="modal__container">
    <div class="modal__body">
      <ng-container><i class="fa-regular fa-envelope-circle-check text-primary font-size-72"></i></ng-container>
      <span class="text-xl font-weight-600 font-secondary lh-26 text-gray-1 margin-top-24">
                <ng-container i18n>¡Verifica tu Email para poder operar!</ng-container></span>
      <div class="description">
        <p class="text-sm font-weight-400 lh-20 mt-4 text-gray-35" i18n>
          Necesitas verificar tu correo para comprar, recibir o transferir accesos en Black iD.
        </p>
        <p class="text-sm font-weight-400 lh-20 mt-4 margin-bottom-0 text-gray-35 font-weight-600" i18n>
          Recibirás un email de verificación en la casilla que utilizaste para la creación de esta cuenta.
        </p>
      </div>
      <div class="btns">
        <button type="button" class="btn btn__md btn__primary font-size-14" (click)="cancel()" i18n>Aceptar</button>
      </div>

      <div class="external-recover">
        <ng-container i18n>
          En caso de no ver el correo en tu Bandeja de entrada, verifica tu casilla de Spam / No Deseado. Si aún no lo
          recibiste, solicita el
        </ng-container>
        <a class="link cursor-pointer" (click)="onAction()">
          <ng-container i18n>
            reenvío nuevamente
          </ng-container>
        </a>.
      </div>
    </div>
  </div>
</div>
